// @flow

import React from 'react';
import { Link } from 'gatsby';

type Props = {
  siteTitle: string,
};

const Header = ({ siteTitle }: Props) => (
  <header>
    <Link to="/">{siteTitle}</Link>
  </header>
);

export default Header;
