// @flow

import React, { type Node } from 'react';
import Helmet from 'react-helmet';
import { injectGlobal } from 'react-emotion';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';

injectGlobal`
  @font-face {
    font-family: 'Founders Grotesk Text';
    font-weight: 400;
    font-style: normal;
    src: url('../assets/fonts/FoundersGroteskTextWeb-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Founders Grotesk Text';
    font-weight: 500;
    font-style: normal;
    src: url('../assets/fonts/FoundersGroteskTextWeb-Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Founders Grotesk Text';
    font-weight: bold;
    font-style: normal;
    src: url('../assets/fonts/FoundersGroteskTextWeb-Bold.woff2') format('woff2');
  }

  html {
    font-size: 16px;
  }

  body {
    font-family: Founders Grotesk Text, -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 1.3125rem;
  }
`;

type Props = {
  children: Node,
};

const Layout = ({ children }: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
          ]}>
          <html lang="en" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>{children}</div>
      </>
    )}
  />
);

export default Layout;
