// @flow

import React, { Component } from 'react';
import styled from 'react-emotion';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

const StyledPostContent = styled.div`
  p + p {
    margin-top: 1rem;
  }
`;

type Props = {
  data: {
    markdownRemark: {
      html: string,
      fields: {
        date: Date,
        slug: string,
      },
      frontmatter: {
        cover: Object,
        title: string,
      },
    },
  },
};

export default class ReviewPage extends Component<Props> {
  render() {
    const { data } = this.props;
    const { markdownRemark: post } = data;

    return (
      <Layout>
        <img
          src={post.frontmatter.cover.childImageSharp.fluid.src}
          alt={post.frontmatter.title}
        />
        <h1>{post.frontmatter.title}</h1>
        <StyledPostContent dangerouslySetInnerHTML={{ __html: post.html }} />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ReviewByPath($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        date
        slug
      }
      frontmatter {
        cover {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
      }
    }
  }
`;
